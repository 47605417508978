import {
  Alert,
  Box,
  Collapse,
  FormControl,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import QButton from '../Main/QButton';
import ForgotPasswordFormLogic from './ForgotPasswordFormLogic';
import CloseIcon from '@mui/icons-material/Close';

const ForgotPasswordForm = () => {
  const {
    handleCustomerForgotPassInput,
    values,
    handleCustomerForgotPassword,
    submitmessage,
    setsubmitmessage,
    openAlert,
    setOpenAlert,
    alertStatus,
    setAlertStatus,
  } = ForgotPasswordFormLogic();
  const inputRefs = useRef({});
  const closeAlert = () => {
    setOpenAlert(false);
    setsubmitmessage('');
  };
  // if (submitmessage) {
  //   setTimeout(() => {
  //     setOpenAlert(false);
  //     setsubmitmessage('');
  //   }, 10000);
  // }
  useEffect(() => {
    for (const fieldName in values.cursorposition) {
      const inputField = inputRefs.current[fieldName];
      if (inputField) {
        inputField.setSelectionRange(
          values.cursorposition[fieldName],
          values.cursorposition[fieldName],
        );
      }
    }
  }, [values]);
  return (
    <>
      <Box
        sx={{ width: '100%' }}
        className={submitmessage ? 'form-submit-error-message' : ''}
      >
        {alertStatus === true &&
        submitmessage === 'Email Send, Please check your Email' ? (
          <Collapse in={openAlert}>
            <Alert
              severity="success"
              action={
                <IconButton
                  className="error-close-icon"
                  aria-label="close"
                  color="success"
                  size="small"
                  onClick={closeAlert}
                >
                  <CloseIcon />
                </IconButton>
              }
              sx={{ mb: 4 }}
            >
              {submitmessage}
            </Alert>
          </Collapse>
        ) : (
          <Collapse in={openAlert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  className="error-close-icon"
                  aria-label="close"
                  color="error"
                  size="small"
                  onClick={closeAlert}
                >
                  <CloseIcon />
                </IconButton>
              }
              sx={{ mb: 4 }}
            >
              {submitmessage}
            </Alert>
          </Collapse>
        )}
      </Box>
      <form className="login-customer-form">
        <FormControl className="login-customer-form" autoComplete="off">
          <h1>Forget Password</h1>
          <span className="sub-heading-from">
            To reset your password, please enter your Email ID & follow the
            instructions.
          </span>
          <div style={{ width: '300px' }}>
            <div className="row">
              <div
                className="col-md-12"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    variant="outlined"
                    className="input-field"
                    id="outlined-size-small"
                    // size="small"
                    autoComplete="off"
                    name="username"
                    value={values.username}
                    onChange={handleCustomerForgotPassInput}
                    // inputProps={{
                    //   'data-field': 'username',
                    //   ref: (input) => (inputRefs.current['username'] = input), // Store the ref in a ref object
                    //   selectionstart: values.cursorposition.username,
                    // }}
                  />
                </FormControl>
                <span className="input-error">{values.errors.username}</span>
              </div>
            </div>
          </div>

          {/* <div className="input-outer-div">
            
          </div> */}
          <QButton
            onClickHandle={handleCustomerForgotPassword}
            name={'Submit'}
          />
        </FormControl>

        {/* <Box
          sx={{ width: '100%', height: '80px' }}
          className={submitmessage ? 'form-submit-error-message' : ''}
        >
          {alertStatus === true ? (
            <Collapse in={openAlert}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    className="error-close-icon"
                    aria-label="close"
                    color="success"
                    size="small"
                    onClick={closeAlert}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {submitmessage}
              </Alert>
            </Collapse>
          ) : (
            <Collapse in={openAlert}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    className="error-close-icon"
                    aria-label="close"
                    color="error"
                    size="small"
                    onClick={closeAlert}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {submitmessage}
              </Alert>
            </Collapse>
          )}
        </Box> */}
      </form>
    </>
  );
};

export default ForgotPasswordForm;
