import { Button, Skeleton } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import AddToWishlistIcon from '../../../Assets/Products/AddtowishlistIcon.svg';
import AddToWishlistFillIcon from '../../../Assets/Products/AddtowishlistFillIcon.svg';
import DeleteIcon from '../../../Assets/Products/delete.svg';
import CartIcon from '../../../Assets/Products/Cart.svg';
import ProductImage from '../../../Assets/Products/bong.png';
import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.png';
import AddIcon from '../../../Assets/Home/plusWhite.svg';
import RemoveIcon from '../../../Assets/Home/minusWhite.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Validation from '../../../Constants/Validation';
import {
  removeCartItem,
  updateCartItem,
} from '../../../redux/features/Cart/cartSlice';
import {
  addToWishlist,
  fetchWishlistData,
  deleteToWishlist,
} from '../../../redux/features/Wishlist/wishlistSlice';
import {
  addToCart,
  updateCartItemfromSingleProduct,
} from '../../../redux/features/Cart/cartSlice';
import {
  BASE_URL_SANDBOX,
  GET_ALL_CART_PRODUCT,
  GET_VARIANT_PRODUCT_QUANTITY,
} from '../../../Constants/Config';
import { ContextSearch } from '../../../Context/SearchContext';
import $ from 'jquery';
import AddBtn from './../../../Assets/Store/add_White_Stroke.svg';
import { useMediaQuery } from '@mui/material';
import { fetchAllCartProductDetails } from '../../../redux/features/Cart/cartProductDetailsSlice';
import axios from 'axios';
const SingleProduct = ({
  product,
  categoryId,
  Wishlist,
  merchantId,
  hideVariantAndWishlist,
  item,
}) => {
  const { isGuest } = useSelector((state) => state.authUser);
  const { checkOrderMethod } = useContext(ContextSearch);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    percentOffItem,
    AddtoCart,
    checkInCart,
    UpdateQuantity,
    getProductQuantity,
    removeProductFromCart,
    handleImagesArray,
  } = Validation();
  const cartDataState = useSelector((state) => state.cart.cartData);
  const singleStoreDataState = useSelector(
    (state) => state.singleStores.storesData,
  );
  const wishlistStateData = useSelector((state) => state.wishlist);
  const authUserData = useSelector((state) => state.authUser.UserData);

  const [updateProductQ, setUpdateProductQ] = useState(false);
  const [addingProductInCart, setAddingProductInCart] = useState(false);
  const [disableQuantityUpdate, setDisableQuantityUpdate] = useState(false);
  const location = useLocation();
  const [inWishlist, setInWishlist] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const orderMethod = searchParams.get('orderMethod');
  // const merchantId = location.pathname.replace('/merchant/', '');
  const [productQuantity, setProductQuantity] = useState();
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleAddToWishlist = () => {
    if (authUserData && authUserData.id) {
      const data = {
        user_id: authUserData && authUserData.id,
        merchants_id: merchantId,
        product_id: product.id,
        category_id: categoryId,
      };
      dispatch(addToWishlist(data));
      setInWishlist(true);

      const datawishlist = {
        id: authUserData && authUserData.id,
        merchant_id: merchantId && merchantId,
      };
      setTimeout(() => {
        if (authUserData && authUserData.id) {
          dispatch(fetchWishlistData(datawishlist));
        }
      }, 1000);
    } else {
      Navigate('/customer-login', {
        state: {
          merchantId: merchantId,
        },
      });
    }
  };
  const handleDeleteToWishlist = () => {
    if (
      authUserData &&
      authUserData.id &&
      product &&
      product.id &&
      merchantId
    ) {
      const data = {
        merchant_id: merchantId,
        user_id: authUserData.id,
        product_id: product.id,
      };
      dispatch(deleteToWishlist(data));
      setInWishlist(false);
      // const datawishlist = {
      //   "id": authUserData && authUserData.id,
      //   "merchant_id": merchantId && merchantId
      // }
      // setTimeout(() => {
      //   if (authUserData && authUserData.id) {
      //     dispatch(fetchWishlistData(datawishlist))
      //   }
      //  }, 1000);
    }
  };
  const handleAddtoCart = async (productId, merchantId, categoryId) => {
    setAddingProductInCart(() => true);
    const cartProduct = {
      cartProductId: new Date().getTime(),
      productId: productId,
      categoryId: categoryId,
      quantity: productQuantity,
      varients: [],
    };

    const response = await axios.post(
      BASE_URL_SANDBOX + GET_ALL_CART_PRODUCT,
      {
        merchant_id: merchantId,
        product_id: productId,
      },
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );

    const product = response.data[0];
    if (
      product.trackqnty === '1' &&
      +product.quantity <= 0 &&
      product.isstockcontinue === '0'
    ) {
      alert('Sorry this Product is Out of Stock at the moment!');
      return;
    }

    // console.log('continue flow....');
    // return;

    setUpdateProductQ(true); // just setting go to cart
    dispatch(addToCart([cartProduct])); // in slice
    AddtoCart(cartProduct, merchantId); // in localstorage

    const cartKey = `cart_${merchantId}`;
    let cartData = JSON.parse(localStorage.getItem(cartKey)) || [];
    if (cartData.length > 0) {
      const productIdsString = cartData.map((item) => item.productId).join(',');

      const data = {
        merchant_id: merchantId,
        product_id: productIdsString,
      };

      dispatch(fetchAllCartProductDetails(data));
    }
    setAddingProductInCart(() => false);
  };

  const handleMouseOver = () => {
    const cartProduct = {
      productId: product.id,
      categoryId: categoryId,
      quantity: productQuantity,
      varients: [],
    };
    if (checkInCart(cartProduct, merchantId)) {
      setUpdateProductQ(true);
    } else {
      setUpdateProductQ(false);
    }
  };

  const handleProductQuantityUpdate = async (productId, categoryId, qty) => {
    const foundItem = cartDataState.find(
      (product) => product.productId === productId, // && product.categoryId === categoryId
    );

    const cartItem = {
      cartProductId: foundItem && foundItem.cartProductId,
      productId: productId,
      // categoryId: categoryId,
      quantity: productQuantity + qty,
    };

    const quantity =
      getProductQuantity(cartDataState, product.id, merchantId, categoryId) +
      qty;

    if (quantity < 1) {
      const cartKey = `cart_${merchantId}`;
      dispatch(removeCartItem(cartItem));
      removeProductFromCart(cartItem, cartKey);
      setUpdateProductQ(false);
    } else {
      if (qty === 1) {
        const data = {
          merchant_id: merchantId,
          product_id: productId,
          variant_id: '',
          quantity: quantity,
        };

        try {
          const response = await axios.post(
            BASE_URL_SANDBOX + GET_VARIANT_PRODUCT_QUANTITY,
            data,
            { headers: { 'Content-Type': 'multipart/form-data' } },
          );

          if (
            response.data.status === 200 &&
            parseInt(response.data.result.quantity) >= quantity
          ) {
            setProductQuantity(quantity);
            setDisableQuantityUpdate(false);

            setProductQuantity(quantity);
            UpdateQuantity(
              product.id,
              merchantId,
              categoryId,
              productQuantity + qty,
            );
            dispatch(updateCartItemfromSingleProduct(cartItem));
          } else if (response.data.status === 400) {
            setDisableQuantityUpdate(true);
          }
        } catch (error) {
          return new Error('Something went wrong');
        }
      } else {
        if (disableQuantityUpdate) setDisableQuantityUpdate(false);
        setProductQuantity(quantity);
        UpdateQuantity(
          product.id,
          merchantId,
          categoryId,
          productQuantity + qty,
        );
        dispatch(updateCartItemfromSingleProduct(cartItem));
      }
    }
  };

  useEffect(() => {
    handleMouseOver();

    if (!authUserData) {
      setInWishlist(false);
    }
    // Check if the product is in the wishlist
    else if (
      wishlistStateData &&
      wishlistStateData.wishlistData &&
      wishlistStateData.wishlistData.length > 0
    ) {
      const isProductInWishlist = wishlistStateData.wishlistData.some(
        (wishlistProduct) =>
          wishlistProduct && wishlistProduct.id === product.id,
      );
      setInWishlist(isProductInWishlist);
    }
  }, [product, cartDataState, wishlistStateData, authUserData]);

  let percentageDiscount = percentOffItem(product.price, product.compare_price);

  useEffect(() => {
    // console.log('cartDataState: ', cartDataState);
    const foundItem =
      cartDataState &&
      cartDataState.find((item) => {
        return item.productId === product.id;
      });
    // console.log('foundItem: ', foundItem);
    setProductQuantity(foundItem?.quantity ?? 1);
    // console.log('update qty...', foundItem?.quantity);
  }, [cartDataState]);

  const imagesArray =
    product && product.media && handleImagesArray(product.media);

  const showLimitedQtyText = useMemo(() => {
    const bool =
      product.isstockcontinue === '0' &&
      product.isvarient === '0' &&
      product.trackqnty === '1' &&
      product.disable === '0';

    const pqQty = product.purchase_qty ? parseFloat(product.purchase_qty) : 0;
    const qty = product.quantity ? parseFloat(product.quantity) : 0;

    let maxQty = 0;

    // if product qty is greater than Purchase Qty
    if (qty > pqQty && pqQty < 5 && pqQty > 0) {
      maxQty = pqQty;
    }

    // if Purchase Qty is greater than actual Qty
    if (pqQty >= qty && qty < 5 && qty > 0) {
      maxQty = qty;
    }

    // if Purchase Qty is not available and actual Qty is > 0 and < 5
    if (pqQty <= 0 && qty > 0 && qty < 5) {
      maxQty = qty;
    }

    return {
      bool,
      quantity: maxQty > 0 ? maxQty : 0,
    };
  }, [product]);

  return (
    <>
      {product && product.id !== null ? (
        <div className="q-sv-single-product">
          {percentageDiscount ? (
            <span className="discout-tag" style={{ background: '#373737' }}>
              {' '}
              {percentageDiscount + '% Off'}
            </span>
          ) : (
            <span></span>
          )}

          {showLimitedQtyText.bool && showLimitedQtyText.quantity > 0 ? (
            <span className="limited-qty-text">
              Only {showLimitedQtyText.quantity} left!
            </span>
          ) : null}

          <Link
            to={`/product/${merchantId}?orderMethod=${
              orderMethod ?? checkOrderMethod(item)
            }&categoryid=${categoryId}&product=${product && product.id}`}
            className="q-sv-single-product-top"
          >
            <img
              alt={product.media}
              onError={(e) => {
                e.target.src = ProductDefaultImage;
              }}
              src={
                product.media
                  ? `${BASE_URL_SANDBOX}upload/products/${merchantId}/${imagesArray[0]}`
                  : ProductDefaultImage
              }
            />
          </Link>
          <div className="q-sv-single-product-bottom">
            <h1
              className=" single-product-card-heading"
              style={{ marginBottom: '0' }}
            >
              {product && product.title && product.title.length > 58
                ? product.title.slice(0, 58) + `...`
                : product.title}
            </h1>
            <div className="q-sv-single-product-bottom-amount">
              <span>{product && `$ ` + product.price} </span>
              <del>
                {' '}
                {product && product.compare_price > product.price
                  ? `$` + product.compare_price
                  : ''}{' '}
              </del>
            </div>
            {percentageDiscount ? (
              <span
                className="discout-tag-mobile"
                style={{ background: '#373737' }}
              >
                {' '}
                {percentageDiscount + '% Off'}{' '}
              </span>
            ) : (
              <span></span>
            )}
            {hideVariantAndWishlist ? null : (
              <div
                className="q-sv-single-product-bottom-purchase"
                style={{ gap: '10px' }}
              >
                {product.isvarient === '1' ? (
                  <>
                    {isMobile ? (
                      <div></div>
                    ) : (
                      <Link
                        className="single-p-select-variant-btn flex-1"
                        to={`/product/${merchantId}?orderMethod=${orderMethod}&categoryid=${
                          categoryId && categoryId
                        }&product=${product?.id}`}
                      >
                        Select Variant
                      </Link>
                    )}
                  </>
                ) : product.disable === '1' ||
                  (+product.quantity <= 0 &&
                    product.isstockcontinue === '0' &&
                    product.trackqnty === '1') ? (
                  <Button disabled className="out-of-stock-btn flex-1">
                    Out of Stock
                  </Button>
                ) : (
                  <>
                    {!updateProductQ ? (
                      <>
                        {isMobile ? (
                          <Button
                            style={updateProductQ ? { display: 'none' } : {}}
                            disabled={addingProductInCart}
                            onClick={() =>
                              handleAddtoCart(
                                product.id,
                                merchantId,
                                categoryId,
                                productQuantity,
                              )
                            }
                          >
                            <i
                              className="fa-solid fa-plus fa-lg single-product-card-add-btn"
                              style={{ color: '#fff' }}
                            ></i>
                          </Button>
                        ) : (
                          <Button
                            disabled={addingProductInCart}
                            style={
                              updateProductQ
                                ? { display: 'none', position: 'absolute' }
                                : {}
                            }
                            onClick={() =>
                              handleAddtoCart(
                                product.id,
                                merchantId,
                                categoryId,
                                productQuantity,
                              )
                            }
                            className="flex-1 d-flex "
                          >
                            <img
                              style={{ position: 'absolute', left: '10px' }}
                              src={CartIcon}
                              alt="cart-icon"
                            />{' '}
                            <p
                              style={{ position: 'absolute', left: '47%' }}
                              className=""
                            >
                              Add
                            </p>
                          </Button>
                        )}
                      </>
                    ) : (
                      <span
                        style={!updateProductQ ? { display: 'none' } : {}}
                        className="product-qti-button flex-1"
                      >
                        <Button
                          variant="contained"
                          // disabled={productQuantity <= 1 ? true : false}
                          className="add-remove-cart-btn"
                          onClick={() =>
                            handleProductQuantityUpdate(
                              product.id,
                              categoryId,
                              -1,
                            )
                          }
                        >
                          <i className="fa-solid fa-minus fa-lg"></i>
                        </Button>
                        <span> {productQuantity} </span>
                        <Button
                          variant="contained"
                          disabled={disableQuantityUpdate}
                          className="add-remove-cart-btn "
                          onClick={() =>
                            handleProductQuantityUpdate(
                              product.id,
                              categoryId,
                              1,
                            )
                          }
                        >
                          <i
                            className="fa-solid fa-plus fa-lg"
                            style={{ color: '#fff' }}
                          ></i>
                        </Button>
                      </span>
                    )}
                  </>
                )}

                {isGuest !== 'yes' && // Check if the user is not a guest
                  (inWishlist ? (
                    <span
                      className="add-wish"
                      onClick={() => handleDeleteToWishlist()}
                    >
                      <img
                        src={AddToWishlistFillIcon}
                        alt="add-to-wishlist-icon"
                      />
                    </span>
                  ) : (
                    <span
                      className="add-wish"
                      onClick={() => handleAddToWishlist()}
                    >
                      <img src={AddToWishlistIcon} alt="add-to-wishlist-icon" />
                    </span>
                  ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default SingleProduct;
